/**
 * Combined by jsDelivr.
 * Original files:
 * - /gh/stretchr/arg.js@1.4.0/dist/arg-1.3.min.js
 * - /npm/scrolltofixed@1.0.6/jquery-scrolltofixed.min.js
 * - /npm/blockui@1.0.0/jquery.blockUI.min.js
 * - /npm/slideout@1.0.1/dist/slideout.min.js
 * - /npm/rellax@1.6.2/rellax.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
